import React from 'react';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

const LiveFaceCompleteDialog = ({ open, onClose, onRetry }) => {
    const emailAddress="support@driveflexi.com"

    return (
        <ClosableDialog open={open} >
            <OnCloseDialogTitle onClose={onClose}>
                <Typography sx={{fontSize: "1.6rem" }}>Something Went Wrong!</Typography>
            </OnCloseDialogTitle> 
           <DialogContent> 
               <Stack alignItems={"center"} spacing={2}>
                   <Typography sx={{fontSize: "1.3rem", fontWeight: 520 }}>Verification failed</Typography>
                   <Typography align="center" sx={{px: 6}}> If problems persist please contact us at <Link  href={`mailto:${emailAddress}`} target="_blank">support@driveflexi.com</Link>
                    </Typography>
               </Stack>
               <Stack direction={"row"} p={2} justifyContent={"center"} alignItems={"center"} pt={4}>
                    <Button fullWidth maxWidth={"300px"} variant={"contained"} color={"primary"} onClick={onRetry}>Try Again</Button>
                </Stack>

           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceCompleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onRetry: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default LiveFaceCompleteDialog;
export { LiveFaceCompleteDialog };

