import React from 'react';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";

import Typography from "@mui/material/Typography";

const LiveFaceCompleteDialog = ({ open, onClose }) => {
    
    return (
        <ClosableDialog open={open} >
            <OnCloseDialogTitle onClose={onClose}>
                <Typography sx={{fontSize: "1.6rem" }}>Check Complete</Typography>
            </OnCloseDialogTitle> 
           <DialogContent> 
               <Stack alignItems={"center"} spacing={2}>
                   <Typography sx={{fontSize: "1.3rem", fontWeight: 520 }}>Thanks</Typography>
                   <Typography align="center" sx={{px: 3}}>Nice to meet you! We’ll verify your selfie against your Emirates ID when you submit an application.</Typography>
               </Stack>
               <Stack direction={"row"} p={2} justifyContent={"center"} alignItems={"center"} pt={4}>
                    <Button fullWidth maxWidth={"300px"} variant={"contained"} color={"primary"} onClick={onClose}>Continue</Button>
                </Stack>

           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceCompleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default LiveFaceCompleteDialog;
export { LiveFaceCompleteDialog };

