import React from 'react';
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

import {FaceLivenessDetector} from "@aws-amplify/ui-react-liveness";

const LiveFaceCheckDialog = ({ open,sessionId,onCancel, onComplete, onError,region }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const displayText = {
        hintCenterFaceText: 'Centre your face',
    };

    return (
        <>
        <GlobalStyles
            styles={{
                '.amplify-liveness-toast__message': {
                    fontSize: fullScreen ? '0.9rem !important' : '1.4rem !important'
                },
                '.amplify-flex': {
                    gap: 'var(--amplify-space-small) !important ',
                },
            }}
        />
        <ClosableDialog open={open} fullScreen={fullScreen} >
            {!fullScreen && 
                <OnCloseDialogTitle >
                    Identity Check
                </OnCloseDialogTitle>
            } 
           <DialogContent sx={{maxHeight:'100%',  display: 'flex', justifyContent: 'center', alignItems: 'center', pt: fullScreen ? 3 : 0, }}>
               <FaceLivenessDetector
                   sessionId={sessionId}
                   onUserCancel={onCancel}
                   region={region}
                   onAnalysisComplete={onComplete}
                   onError={(err) => {
                       console.error(err);
                       onError("Unable to complete liveness")
                   }}
                   displayText={displayText}
               />
           </DialogContent>
        </ClosableDialog>
        </>
    );
};

LiveFaceCheckDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    sessionId:PropTypes.string.isRequired,
    region:PropTypes.string.isRequired,
    onCancel:PropTypes.func.isRequired,
    onComplete:PropTypes.func.isRequired,
    onError:PropTypes.func.isRequired
};

export default LiveFaceCheckDialog;
export { LiveFaceCheckDialog };

