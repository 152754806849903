import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";

import Typography from "@mui/material/Typography";

const LiveFaceStartDialog = ({ open, onStart }) => {

    return (
        <ClosableDialog open={open} >
            <OnCloseDialogTitle>
                <Typography sx={{fontSize: "1.6rem" }}>Selfie Time!</Typography>
            </OnCloseDialogTitle> 
           <DialogContent> 
               <Stack alignItems={"center"} spacing={2}>
                   <Typography sx={{fontSize: "1.3rem", fontWeight: 520 }}>Facial ID Check</Typography>
                   <Typography align="center" sx={{px: 1}}>To get you underway we need to take a quick selfie using your phone camera or computer webcam.</Typography>
                   
               </Stack>
               <Stack direction={"row"} p={2} justifyContent={"center"} alignItems={"center"} pt={4}>
                    <Button fullWidth maxWidth={"300px"} variant={"contained"} color={"primary"} onClick={onStart}>Let's go!</Button>
                </Stack>

           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceStartDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onStart: PropTypes.func.isRequired,
};

export default LiveFaceStartDialog;
export { LiveFaceStartDialog };

