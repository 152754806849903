import React from 'react';
import PropTypes from 'prop-types';

const SelectedDealershipContext = React.createContext({
    loading: false,
    availableDealerships: [],
    selectedDealership: null,
    dealershipUser: null,
    onSelectDealership: () => {},
});

export {SelectedDealershipContext};
export default SelectedDealershipContext

const SelectableDealershipProps = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    logo: PropTypes.string,
});

const DealershipUserProps = PropTypes.shape({
    id: PropTypes.string.isRequired,
    permissionCanAssignInventory: PropTypes.bool,
});

SelectedDealershipContext.propTypes = {
    loading: PropTypes.bool.isRequired,
    availableDealerships: PropTypes.arrayOf(SelectableDealershipProps).isRequired,
    selectedDealership: SelectableDealershipProps,
    dealershipUser: DealershipUserProps,
    onSelectDealership: PropTypes.func,
}
